import React from 'react';
import { cn } from '@lib/utils';

interface ContainerProps {
  width?: 'default' | 'full';
  children?: React.ReactNode;
  className?: string;
}

const Container: React.FC<ContainerProps> = ({ children, className, width = 'default' }) => {
  return (
    <div>
      <div
        className={cn(
          'relative flex flex-col px-4 md:px-10 xl:px-20 w-full m-auto pt-6 lg:pt-10 gap-6 pb-0',
          width === 'default' ? 'max-w-screen-2xl' : 'w-full',
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Container;

import { Svg } from '../../_design_system';
import { ComponentProps } from 'react';

const Brand = <T extends ComponentProps<typeof Svg>>({ ...props }: T) => (
  <Svg viewBox="0 0 163 14" fill="fill-primary" stroke="none" {...props}>
    <path d="M5.202 0.204988H0V13.705H3.186V10.303C3.672 10.393 4.212 10.429 4.842 10.429C8.568 10.429 10.926 8.43099 10.926 5.13699C10.926 2.23899 8.748 0.204988 5.202 0.204988ZM4.752 7.74699C4.176 7.74699 3.654 7.71099 3.186 7.63899V2.95899H4.95C6.894 2.95899 7.704 3.98499 7.704 5.24499C7.704 6.73899 6.894 7.74699 4.752 7.74699Z" />
    <path d="M26.3687 13.705L23.7947 8.97099C25.2527 8.16099 26.0987 6.75699 26.0987 4.83099C26.0987 1.93299 24.0647 0.204988 20.5367 0.204988H14.9927V13.705H18.1787V9.70899C18.6827 9.79899 19.2407 9.85299 19.8887 9.85299C20.2307 9.85299 20.5547 9.83499 20.8607 9.81699L22.9487 13.705H26.3687ZM19.7987 7.27899C19.2047 7.27899 18.6647 7.24299 18.1787 7.15299V2.95899H20.2307C22.1567 2.95899 22.8767 3.73299 22.8767 4.99299C22.8767 6.48699 21.9227 7.27899 19.7987 7.27899Z" />
    <path d="M36.583 13.993C40.687 13.993 43.657 10.969 43.657 6.99099C43.657 3.01299 40.687 0.00698853 36.583 0.00698853C32.533 0.00698853 29.545 3.01299 29.545 6.99099C29.545 10.969 32.533 13.993 36.583 13.993ZM36.583 11.095C34.297 11.095 32.677 9.31299 32.677 6.99099C32.677 4.63299 34.297 2.86899 36.583 2.86899C38.887 2.86899 40.525 4.63299 40.525 6.99099C40.525 9.31299 38.887 11.095 36.583 11.095Z" />
    <path d="M63.9672 13.705L61.8792 0.078989H61.5012L55.6152 8.21499L49.6212 0.078989H49.2432L47.1552 13.705H50.2692L51.2772 6.68499L55.3632 12.445H55.8492L59.8452 6.72099L60.8532 13.705H63.9672Z" />
    <path d="M73.5226 0.204988H68.3205V13.705H71.5065V10.303C71.9925 10.393 72.5326 10.429 73.1626 10.429C76.8885 10.429 79.2466 8.43099 79.2466 5.13699C79.2466 2.23899 77.0686 0.204988 73.5226 0.204988ZM73.0725 7.74699C72.4966 7.74699 71.9745 7.71099 71.5065 7.63899V2.95899H73.2706C75.2146 2.95899 76.0246 3.98499 76.0246 5.24499C76.0246 6.73899 75.2145 7.74699 73.0725 7.74699Z" />
    <path d="M84.9932 13.705H88.1432V2.94099H91.1312V0.204988H81.9872V2.94099H84.9932V13.705Z" />
    <path d="M110.86 0.204988V5.22699H104.794V0.204988H101.644V13.705H104.794V8.08899H110.86V13.705H114.01V0.204988H110.86Z" />
    <path d="M125.215 13.993C128.941 13.993 131.371 11.653 131.371 7.94499V0.204988H128.221V7.96299C128.221 9.83499 127.105 11.113 125.215 11.113C123.343 11.113 122.227 9.83499 122.227 7.96299V0.204988H119.077V7.94499C119.077 11.653 121.561 13.993 125.215 13.993Z" />
    <path d="M145.771 0.204988V7.72899L136.771 0.0969887H136.429V13.705H139.507V6.12699L148.525 13.813H148.867V0.204988H145.771Z" />
    <path d="M156.019 13.705H159.169V2.94099H162.157V0.204988H153.013V2.94099H156.019V13.705Z" />
  </Svg>
);

Brand.defaultProps = {
  height: 14,
  width: 163,
  color: 'white'
};

export default Brand;

import Brand from '@components/Brand/Brand';
import React from 'react';
import Link from 'next/link';
import Container from '@components/Container/Container';
import PicStudioLogo from '@components/PicStudioLogo/PicStudioLogo';
import { BsInstagram, BsTiktok, BsTwitter } from 'react-icons/bs';

const PACK_BASE_URL = '/packs/browse/';

const aiPhotoPacks = [
  { name: '1940s Beach', id: 'cm02y62af00021r61tdew57eu' },
  { name: '1950 Corporate', id: 'cllpoxgpg000bmn0fchodak84' },
  { name: '1970 Corporate', id: 'cllvory060001lb0f9j6248qj' },
  { name: '1980 Corporate', id: 'cllpfbs0l000hmk0f69oixhzh' },
  { name: '1990 Corporate', id: 'clloca59g000ml10fwlwhhwg0' },
  { name: 'America', id: 'cm07dhae30001ao3ylpi5ke5f' },
  { name: 'Around the World', id: '042474f7-e7de-42ae-b836-93f5b84140f5' },
  { name: 'Atrium Afternoon', id: 'cm08j5hi30003aw90ybnv61ak' },
  { name: 'Bully Mode', id: 'cm0789wmf0001umc8ovcv318q' },
  { name: 'Casual Colors', id: 'cm0bc1x3i0001m7utu8kwr0lz' },
  { name: 'Classic Car Garage', id: 'cm08j5hi30003aw90ybnv61aq' },
  { name: 'Coastal Reeds', id: 'cm08j5hi30003aw90ybnv61ar' },
  { name: 'Commercial Studio', id: 'cm15ryfhf0001x1nhyy2pq5iq' },
  { name: 'Cool Like That', id: 'cm048zl650001x1rk0gramtgj' },
  { name: 'Cosplay', id: 'cm07bivmx0001te3unbzmiwir' },
  { name: 'Dating Profile', id: 'cm05gknrr0001vw563rt23y7a' },
  { name: 'Desert Landscape', id: 'cm08j5hi30003aw90ybnv61an' },
  { name: 'Ed Wood', id: 'cm04mv0xv000110vb9yigf2mr' },
  { name: 'Elegant Wedding', id: 'cm02zu44y0001bsnloo32cww6' },
  { name: 'Elf', id: 'cm08j587v0001aw90km9s5orc' },
  { name: 'Fall Fashion 2024', id: 'cm12icfbf0001og0qt2ttpjr0' },
  { name: 'Field Biologist', id: 'cm04jz6n00001ytyt8rbop8l0' },
  { name: 'Flowers', id: 'ee5e66c4-e70b-418d-a095-4fcd2dc0f605' },
  { name: 'Game of Thrones', id: 'cm08j5hi30003aw90ybnv61ah' },
  { name: 'Golden Field', id: 'cm08j5hi30003aw90ybnv61au' },
  { name: 'Graphic Designer', id: 'cm08j5hi30003aw90ybnv61ai' },
  { name: 'Hollywood Glamour', id: 'cm05hiahn0001npje2tjfl4h1' },
  { name: 'Industrial Designer', id: 'cm08j5hi30003aw90ybnv61aj' },
  { name: 'Lakefront', id: 'cm08j5hi30003aw90ybnv61am' },
  { name: 'Mezzotint', id: 'clm9sec3k0002js0fqe7d1x2d' },
  { name: 'Misty Boardwalk', id: 'cm08j5hi30003aw90ybnv61ao' },
  { name: 'Monochrome', id: 'cm11d0gns0001nw9chgfm9wpt' },
  { name: 'Moody Studio', id: '7874ce59-ee76-4d73-88ff-5d448ab42e77' },
  { name: 'Office Pro', id: '1b58ebef-5e10-4607-9b2b-40698f711d48' },
  { name: 'Polaroid', id: 'cm1awwuqw00019v351tocl43t' },
  { name: 'Polaroid Wall', id: 'clmjqaj5d001hmh0fnfhqkgnz' },
  { name: 'Retro Street', id: 'cm08j5hi30003aw90ybnv61ap' },
  { name: 'Rooftop Garden', id: 'cm18erh8w0001usfroydiz5fy' },
  { name: 'Rustic Pro', id: 'cm04jp7190001n0j3two6iv3c' },
  { name: 'Street Y2K', id: 'clzygp2t70002pvzazdpvzbiv' },
  { name: 'Studio Director', id: 'cm15gmydb0001o1ibgzd8ensd' },
  { name: 'Tintype Photography', id: 'cm0h621zn0001y5asm8773ish' },
  { name: 'Tranquil Cliff Meditation', id: 'cm08fgr4900014ly3u3lgrs4g' },
  { name: 'Vintage Photo Booth', id: 'cm0tqg5hs000113zbts0d4ey6' },
  { name: 'Wes Anderson', id: 'cm0ithyfn0001yjrbfescqknm' },
  { name: 'WeWork', id: 'cm077gkmu0001cwcem49ijzsg' },
  { name: 'Wheat Field', id: 'cm08j5hi30003aw90ybnv61as' },
  { name: 'Wiltern', id: 'cm08ca1m5000142yjc24d12j7' },
  { name: 'Wired Portrait', id: 'clzzqill30001mavqre7dn04h' },
  { name: 'Youtube Portraits', id: 'cm0ie9q6e0001uqv4ih1mm1vy' }
];

const navigation = {
  pages: [
    { name: 'AI Photo Generator', href: '/ai-photo-generator' },
    { name: 'Photo Packs', href: '/packs/browses' },
    { name: 'Reviews & Examples', href: '/pricing' },
    { name: 'Pricing', href: '/pricing' },
    { name: 'Gift AI Photos', href: '/pricing?gift=true' },
    { name: 'Refer A Friend', href: '/refer' },
    { name: 'FAQ', href: '/faq' },
    { name: 'Support', href: '/support' }
  ],
  legal: [
    { name: 'Privacy', href: '/privacy' },
    { name: 'Terms', href: '/terms' }
  ],
  ourFriends: [
    { name: 'Prompt Hunt', href: 'https://prompthunt.com/' },
    { name: 'Prompt Templates', href: 'https://www.prompthunt.com/themes' },
    { name: 'Karaoke Smash', href: 'https://www.karaokesmash.com/' },
    { name: 'Habit Coach', href: 'https://habitcoach.ai/' }
  ],
  social: [
    {
      name: 'TikTok',
      href: 'https://www.tiktok.com/@picstudioai',
      icon: BsTiktok
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/picstudioai/',
      icon: BsInstagram
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/picstudioai',
      icon: BsTwitter
    }
  ]
};

export default function Example() {
  return (
    <Container>
      <footer className="w-full" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className=" mx-auto w-full pt-10 pb-24">
          <div className="grid xl:grid-cols-5 xl:gap-8 gap-10">
            <div className="bg-blue-500/0 space-y-4 text-xs leading-5">
              <div className="flex flex-col items-center md:items-start">
                <PicStudioLogo className="mb-6" />
                <div className="mb-1 text-muted-foreground">A product by</div>
                <Link href="https://prompthunt.com" target="_blank">
                  <Brand className="fill-primary" width={128} />
                </Link>
                <p className="pt-4 text-primary/50">&copy; 2023 Prompt Hunt Inc. All rights reserved.</p>
              </div>
              <div className="flex space-x-6 justify-center md:justify-start">
                {navigation.social.map(item => (
                  <Link key={item.name} href={item.href} className="text-muted-foreground hover:text-primary">
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6 stroke-none" aria-hidden="true" />
                  </Link>
                ))}
              </div>
            </div>
            <div className=" bg-red-500/0 xl:col-span-3 space-y-5">
              <h3 className="text-sm font-semibold leading-6 text-current">AI Photo Packs</h3>
              <ul role="list" className="bg-red-500/0 gap-2 grid md:grid-cols-3 lg:grid-cols-3">
                {aiPhotoPacks.map(item => (
                  <li key={item.id}>
                    <Link
                      href={`${PACK_BASE_URL}${item.id}`}
                      className="text-sm leading-6 text-muted-foreground hover:text-primary"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="bg-purple-500/0 grid md:grid-cols-3 xl:grid-cols-1 h-fit gap-10">
              <div className="flex flex-col gap-5">
                <h3 className="text-sm font-semibold leading-6 text-current">Site Map</h3>
                <ul role="list" className="grid gap-2">
                  {navigation.pages.map(item => (
                    <li key={item.name}>
                      <Link href={item.href} className="text-sm leading-6 text-muted-foreground hover:text-current">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex flex-col gap-5">
                <h3 className="text-sm font-semibold leading-6 text-current">Legal</h3>
                <ul role="list" className="grid gap-2">
                  {navigation.legal.map(item => (
                    <li key={item.name}>
                      <Link href={item.href} className="text-sm leading-6 text-muted-foreground hover:text-current">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex flex-col gap-5">
                <h3 className="text-sm font-semibold leading-6 text-current">More AI Sites</h3>
                <ul role="list" className="grid gap-2">
                  {navigation.ourFriends.map(item => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        className="text-sm leading-6 text-muted-foreground hover:text-current"
                        target="_blank"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Container>
  );
}
